import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61')
];

export const server_loads = [0];

export const dictionary = {
		"/": [9],
		"/contact": [50],
		"/(app)/discover": [29,[2]],
		"/(app)/(private)/healthkit": [10,[2,3]],
		"/(app)/(private)/home": [11,[2,3]],
		"/login": [~51],
		"/logout": [~52],
		"/(app)/(private)/mocks/auth/signin": [12,[4]],
		"/(app)/(private)/mocks/auth/waitlist": [13,[4]],
		"/(app)/(private)/my/progress": [14,[2,3]],
		"/(app)/(private)/my/protocols": [15,[2,3]],
		"/(app)/(private)/my/protocols/[slug]": [16,[2,3]],
		"/(app)/(private)/my/stack/nutrients": [17,[2,3,5]],
		"/(app)/(private)/my/stack/products": [18,[2,3,5]],
		"/(app)/(private)/my/stack/protocols": [19,[2,3,5]],
		"/(app)/products": [30,[2]],
		"/(app)/(private)/products/edit": [20,[2,3]],
		"/(app)/products/popular": [32,[2]],
		"/(app)/(private)/products/submit": [21,[2,3]],
		"/(app)/products/[slug]": [31,[2]],
		"/(app)/profiles/popular": [36,[2]],
		"/(app)/profiles/[slug]": [33,[2]],
		"/(app)/profiles/[slug]/collections/goals/[goal]": [34,[2]],
		"/(app)/profiles/[slug]/collections/quick-details": [35,[2]],
		"/(app)/(private)/profile": [22,[2,3]],
		"/(app)/protocols": [37,[2]],
		"/(app)/protocols/[slug]": [38,[2]],
		"/(app)/protocols/[slug]/match-confirm": [39,[2]],
		"/(app)/protocols/[slug]/match-products": [40,[2]],
		"/(app)/protocols/[slug]/select-products": [41,[2]],
		"/(app)/(private)/reports/usda-nutrients": [23,[2,3]],
		"/(app)/(private)/saves": [24,[2,3]],
		"/(app)/scanner": [42,[2]],
		"/sentry-example": [53],
		"/(app)/(private)/settings": [25,[2,3]],
		"/(app)/(private)/settings/profile": [26,[2,3]],
		"/signup": [~54],
		"/(app)/stacks/users/[slug]": [43,[2,6]],
		"/(app)/stacks/users/[slug]/nutrients": [44,[2,6]],
		"/(app)/stacks/users/[slug]/products": [45,[2,6]],
		"/(app)/stacks/users/[slug]/protocols": [46,[2,6]],
		"/(app)/supplements": [47,[2]],
		"/(app)/supplements/popular": [49,[2]],
		"/(app)/supplements/[slug]": [48,[2]],
		"/waitlist": [55,[7]],
		"/waitlist/[waitlistId]": [56,[7,8]],
		"/waitlist/[waitlistId]/accepted": [57,[7,8]],
		"/waitlist/[waitlistId]/access_code": [58,[7,8]],
		"/waitlist/[waitlistId]/edit": [59,[7,8]],
		"/waitlist/[waitlistId]/registered": [60,[7,8]],
		"/waitlist/[waitlistId]/waiting": [61,[7,8]],
		"/(app)/(private)/welcome": [27,[2,3]],
		"/(app)/(private)/welcome/waitlist": [28,[2,3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';